import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonService } from 'src/app-core/services/common.service';
import { CommonDataModel } from '../common-data-model';

@Component({
  selector: 'app-save-quote-details-page',
  templateUrl: './save-quote-details-page.component.html',
  styleUrls: ['./save-quote-details-page.component.scss']
})
export class SaveQuoteDetailsPageComponent implements OnInit {
commonDataModel = new CommonDataModel();
    componentSubscriptions: Subscription = new Subscription();
    trackingDetailsGetModel = this.commonDataModel.trackingDetailsGetModel;
    documentList: Array<any> = [];
    pageName = CommonConstants.pageName.shipmentDocument;
    truckloadModule = CommonConstants.moduleName.scheduleTruckload;
    moduleName: string;
    isQuickRate = true;
    purchaseLabels: any;
    showAlertServiceForm = true;
    mode: any;
    hideFetchDocButton = true;
    tlSummary = true;
    showAlertServicesInLtl = false;
    insuredAmount: any = 0;
    constructor(
        private router: Router,
        private commonService: CommonService,
        private activeRoute: ActivatedRoute) { }

    ngOnInit() {
        this.componentSubscriptions.add(this.commonService.purchaseLabels.subscribe((purchaseLabels: any) => {
            this.purchaseLabels = purchaseLabels;
        }))

        this.moduleName = this.activeRoute.snapshot.queryParamMap.get('moduleName');
        this.mode = this.activeRoute.snapshot.queryParamMap.get('mode');
        this.activeRoute.paramMap.subscribe((params: any) => {
            this.trackingDetailsGetModel.ownerId = this.commonService.getOwnerId();
            this.trackingDetailsGetModel.shipmentId = params.get('id');
            this.getShipmentDetails();
        })
    }

    onNavigate(): void {
      switch (this.moduleName) {
          case CommonConstants.moduleName.scheduleShipment:
          case CommonConstants.moduleName.scheduleLTL:
              this.router.navigateByUrl('app/shipment/shipment-booking/schedule-shipment/new');
              break;
          case CommonConstants.moduleName.quickRate:
              this.router.navigateByUrl('app/quick-rate');
              break;
          default:
              break;
      }
  }

    getShipmentDetails() {
            this.commonService.getTrackingDetails(this.trackingDetailsGetModel).subscribe((response: any) => {
                  this.commonService.sendBOLnumber(response.bolNumber);
            })
    }

    showBookButtonLabel() {
      if (this.moduleName === CommonConstants.moduleName.scheduleShipment) {
          return true;
      } else {
          return false;
      }
  }
}
