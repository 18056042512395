import { AgmCoreModule } from '@agm/core';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import * as ngCommon from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule, MatTreeModule } from '@angular/material';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { EditorModule } from '@progress/kendo-angular-editor';
import { GridModule } from '@progress/kendo-angular-grid';
import { PagerModule } from '@progress/kendo-angular-pager';
import { PopupModule } from '@progress/kendo-angular-popup';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import 'hammerjs';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ToastrModule } from 'ngx-toastr';
import { UtilsModule } from 'src/app-core/utils.module';
import { environment } from 'src/environments/environment';
import { BiAlertDialogComponent } from './bi-alert-dialog/bi-alert-dialog.component';
import { CommonDialogComponent } from './common-dialog/common-dialog.component';
import { ConfirmClaimsDialogComponent } from './confirm-claims-dialog/confirm-claims-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ConfirmRadioDialogComponent } from './confirm-radio-dialog/confirm-radio-dialog.component';
import { ContactListComponent } from './contact-list/contact-list.component';
import { CopyInputComponent } from './copy-input/copy-input.component';
import { CustomDialogComponent } from './custom-dialog/custom-dialog.component';
import { CustomKendoFilterComponent } from './custom-kendo-filter/custom-kendo-filter.component';
import { DateRangeFilterComponent } from './date-range-filter/date-range-filter.component';
import { EmailChipListComponent } from './email-chip-list/email-chip-list.component';
import { EmailRecipientModalComponent } from './email-recipient-list/email-recipient-list.component';
import { FeedbackFormComponent } from './feedback-form/feedback-form.component';
import { FilterSelectionFormComponent } from './filter-selection/filter-selection-form/filter-selection-form.component';
import { FilterSelectionComponent } from './filter-selection/filter-selection.component';
import { ImportFormDialogComponent } from './import-form-dialog/import-form-dialog.component';
import { ImportGridListComponent } from './import-grid-list/import-grid-list.component';
import { ImportItemListComponent } from './import-item-list/import-item-list.component';
import { InformationDialogComponent } from './information-dialog/information-dialog.component';
import { LoaderComponent } from './loader/loader.component';
import { PrintCarrierRatesComponent } from './print-carrier-rates/print-carrier-rates.component';
import { PrintDocumentComponent } from './print-document/print-document.component';
import { ShipmentAddressBookComponent } from './shipment-address-book/shipment-address-book.component';
import { ShipmentAddressFormComponent } from './shipment-address-form/shipment-address-form.component';
import { ShipmentAddressSaveComponent } from './shipment-address-save/shipment-address-save.component';
import { ShipmentAddressViewComponent } from './shipment-address-view/shipment-address-view.component';
import { ShipmentAddressComponent } from './shipment-address/shipment-address.component';
import { ShipmentBolInstructionsComponent } from './shipment-bol-instructions/shipment-bol-instructions.component';
import { ShipmentDocumentsComponent } from './shipment-documents/shipment-documents.component';
import { ShipmentEquipmentComponent } from './shipment-equipment/shipment-equipment.component';
import { ShipmentItemSearchComponent } from './shipment-item-search/shipment-item-search.component';
import { ShipmentMultiClassItemsComponent } from './shipment-multi-class-items/shipment-multi-class-items.component';
import { ShipmentRateComponent } from './shipment-rate/shipment-rate.component';
import { ShipmentReferencesComponent } from './shipment-references/shipment-references.component';
import { ShipmentRetryFormComponent } from './shipment-retry-form/shipment-retry-form.component';
import { ShipmentSelectionDialogComponent } from './shipment-selection-dialog/shipment-selection-dialog.component';
import { ShipmentServicesComponent } from './shipment-services/shipment-services.component';
import { ShipmentStatusFormComponent } from './shipment-status-form/shipment-status-form.component';
import { ShipmentTimeOutDialogComponent } from './shipment-time-out-dialog/shipment-time-out-dialog.component';
import { StepsToEnableCookieComponent } from './steps-to-enable-cookie/steps-to-enable-cookie.component';
import { AddressInfoComponent } from './tracking-detail-tabs/address-info/address-info.component';
import { CarrierInfoComponent } from './tracking-detail-tabs/carrier-info/carrier-info.component';
import { DetailsTabComponent } from './tracking-detail-tabs/details-tab/details-tab.component';
import { EquipmentsInfoComponent } from './tracking-detail-tabs/equipments-info/equipments-info.component';
import { HistoryTabComponent } from './tracking-detail-tabs/history-tab/history-tab.component';
import { InvoiceCommentsComponent } from './tracking-detail-tabs/invoice-comments/invoice-comments.component';
import { InvoiceSummaryComponent } from './tracking-detail-tabs/invoice-summary/invoice-summary.component';
import { InvoiceTabComponent } from './tracking-detail-tabs/invoice-tab/invoice-tab.component';
import { ItemsInfoComponent } from './tracking-detail-tabs/items-info/items-info.component';
import { NotesTabComponent } from './tracking-detail-tabs/notes-tab/notes-tab.component';
import { ReferencesInfoComponent } from './tracking-detail-tabs/references-info/references-info.component';
import { ServicesInfoComponent } from './tracking-detail-tabs/services-info/services-info.component';
import { TerminalTabComponent } from './tracking-detail-tabs/terminal-tab/terminal-tab.component';
import { TrackingDetailsComponent } from './tracking-details/tracking-details.component';
import { TrackingMapFilterComponent } from './tracking-map-filter/tracking-map-filter.component';
import { TrackingMapViewPopupComponent } from './tracking-map-view-popup/tracking-map-view-popup.component';
import { TrackingMapViewComponent } from './tracking-map-view/tracking-map-view.component';
import { TrackingWarningDialogComponent } from './tracking-warning-dialog/tracking-warning-dialog.component';
import { TreeDropdownComponent } from './tree-dropdown/tree-dropdown.component';
import { TreePopupComponent } from './tree-popup/tree-popup.component';
import { WarningDialogComponent } from './warning-dialog/warning-dialog.component';
import { UploadDocumentFileComponent } from './upload-document-file/upload-document-file.component';
import { ParcelInformationDialogComponent } from './parcel-information-dialog/parcel-information-dialog.component';
import { ShipmentParcelRatesComponent } from './shipment-parcel-rates/shipment-parcel-rates.component';
import { InsuranceQuoteDialogComponent } from './insurance-quote-dialog/insurance-quote-dialog.component';
import { InsuranceRateDialogComponent } from './insurance-rate-dialog/insurance-rate-dialog.component';
import { SaveQuoteDetailsPageComponent } from './save-quote-details-page/save-quote-details-page.component';

@NgModule({
    imports: [
        ngCommon.CommonModule,
        FormsModule,
        ReactiveFormsModule,
        GridModule,
        PagerModule,
        TooltipModule,
        DateInputsModule,
        DropDownsModule,
        PopupModule,
        MatMenuModule,
        MatInputModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatRadioModule,
        MatDialogModule,
        MatExpansionModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatBadgeModule,
        MatSlideToggleModule,
        MatTooltipModule,
        MatTabsModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatListModule,
        MatIconModule,
        MatChipsModule,
        MatSidenavModule,
        MatPaginatorModule,
        UtilsModule,
        PdfViewerModule,
        RecaptchaFormsModule,
        RecaptchaModule,
        ToastrModule.forRoot({
            closeButton: true,
            progressBar: true,
            progressAnimation: 'increasing',
            positionClass: 'toast-bottom-right',
            timeOut: 5000,
            preventDuplicates: true
        }),
        AgmCoreModule.forRoot({
            apiKey: environment.googleAPIKey,
            libraries: ['places']
        }),
        TreeViewModule,
        MatTreeModule,
        ColorPickerModule,
        AgmSnazzyInfoWindowModule,
        EditorModule,
        NgxMatSelectSearchModule
    ],
    declarations: [
        LoaderComponent,
        ConfirmDialogComponent,
        ConfirmRadioDialogComponent,
        ShipmentItemSearchComponent,
        ShipmentServicesComponent,
        ShipmentEquipmentComponent,
        ShipmentRateComponent,
        PrintDocumentComponent,
        FilterSelectionComponent,
        TreePopupComponent,
        DateRangeFilterComponent,
        TreeDropdownComponent,
        ShipmentAddressBookComponent,
        ShipmentAddressSaveComponent,
        ShipmentAddressComponent,
        ShipmentReferencesComponent,
        ShipmentBolInstructionsComponent,
        ContactListComponent,
        EmailRecipientModalComponent,
        EmailChipListComponent,
        CopyInputComponent,
        ShipmentMultiClassItemsComponent,
        WarningDialogComponent,
        CommonDialogComponent,
        ShipmentRetryFormComponent,
        PrintCarrierRatesComponent,
        FilterSelectionFormComponent,
        ShipmentDocumentsComponent,
        TrackingWarningDialogComponent,
        TrackingMapViewComponent,
        AddressInfoComponent,
        CarrierInfoComponent,
        ServicesInfoComponent,
        EquipmentsInfoComponent,
        ReferencesInfoComponent,
        ItemsInfoComponent,
        DetailsTabComponent,
        NotesTabComponent,
        HistoryTabComponent,
        TerminalTabComponent,
        TrackingMapViewPopupComponent,
        TrackingMapFilterComponent,
        TrackingDetailsComponent,
        ShipmentAddressViewComponent,
        ShipmentAddressFormComponent,
        InvoiceTabComponent,
        InvoiceSummaryComponent,
        InvoiceCommentsComponent,
        InformationDialogComponent,
        ShipmentTimeOutDialogComponent,
        CustomKendoFilterComponent,
        ShipmentStatusFormComponent,
        BiAlertDialogComponent,
        ImportItemListComponent,
        StepsToEnableCookieComponent,
        ShipmentSelectionDialogComponent,
        FeedbackFormComponent,
        ImportFormDialogComponent,
        ImportGridListComponent,
        CustomDialogComponent,
        ConfirmClaimsDialogComponent,
        ShipmentTimeOutDialogComponent,
        UploadDocumentFileComponent,
        ParcelInformationDialogComponent,
        ShipmentParcelRatesComponent,
        InsuranceQuoteDialogComponent,
        InsuranceRateDialogComponent,
        SaveQuoteDetailsPageComponent
    ],
    exports: [
        GridModule,
        PagerModule,
        TooltipModule,
        PopupModule,
        DateInputsModule,
        DropDownsModule,
        MatMenuModule,
        MatInputModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatRadioModule,
        MatDialogModule,
        MatExpansionModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatBadgeModule,
        MatSlideToggleModule,
        MatTooltipModule,
        MatTabsModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatListModule,
        MatIconModule,
        MatChipsModule,
        MatSidenavModule,
        MatPaginatorModule,
        NgxMatSelectSearchModule,
        ConfirmDialogComponent,
        ConfirmRadioDialogComponent,
        ShipmentItemSearchComponent,
        ShipmentServicesComponent,
        ShipmentEquipmentComponent,
        ShipmentRateComponent,
        ShipmentParcelRatesComponent,
        PrintDocumentComponent,
        FilterSelectionComponent,
        DateRangeFilterComponent,
        LoaderComponent,
        UtilsModule,
        PdfViewerModule,
        TreePopupComponent,
        TreeDropdownComponent,
        TreeViewModule,
        MatTreeModule,
        ColorPickerModule,
        AgmCoreModule,
        AgmSnazzyInfoWindowModule,
        ShipmentAddressBookComponent,
        ShipmentAddressSaveComponent,
        ShipmentAddressComponent,
        ShipmentReferencesComponent,
        ShipmentBolInstructionsComponent,
        ShipmentMultiClassItemsComponent,
        ContactListComponent,
        EmailRecipientModalComponent,
        EmailChipListComponent,
        CopyInputComponent,
        EditorModule,
        WarningDialogComponent,
        CommonDialogComponent,
        ShipmentRetryFormComponent,
        PrintCarrierRatesComponent,
        FilterSelectionFormComponent,
        ShipmentDocumentsComponent,
        TrackingWarningDialogComponent,
        TrackingMapViewComponent,
        AddressInfoComponent,
        CarrierInfoComponent,
        ServicesInfoComponent,
        EquipmentsInfoComponent,
        ReferencesInfoComponent,
        ItemsInfoComponent,
        DetailsTabComponent,
        NotesTabComponent,
        HistoryTabComponent,
        TerminalTabComponent,
        TrackingMapViewPopupComponent,
        TrackingMapFilterComponent,
        TrackingDetailsComponent,
        ShipmentAddressViewComponent,
        ShipmentAddressFormComponent,
        InvoiceTabComponent,
        InvoiceSummaryComponent,
        InvoiceCommentsComponent,
        InformationDialogComponent,
        ShipmentTimeOutDialogComponent,
        CustomKendoFilterComponent,
        ShipmentStatusFormComponent,
        BiAlertDialogComponent,
        CustomDialogComponent,
        ImportItemListComponent,
        StepsToEnableCookieComponent,
        ShipmentSelectionDialogComponent,
        ConfirmClaimsDialogComponent,
        InsuranceQuoteDialogComponent,
        InsuranceRateDialogComponent,
        FeedbackFormComponent,
        ImportFormDialogComponent,
        ImportGridListComponent,
        UploadDocumentFileComponent,
        ParcelInformationDialogComponent,
        SaveQuoteDetailsPageComponent
    ],
    providers: [
        ngCommon.DatePipe,
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: { siteKey: environment.recaptchaSiteKeyV2, } as RecaptchaSettings
        }
    ],
    entryComponents: [
        TreePopupComponent,
        TreeDropdownComponent,
        ConfirmDialogComponent,
        ConfirmRadioDialogComponent,
        ShipmentItemSearchComponent,
        ShipmentAddressBookComponent,
        ShipmentAddressSaveComponent,
        EmailRecipientModalComponent,
        WarningDialogComponent,
        CommonDialogComponent,
        ShipmentRetryFormComponent,
        FilterSelectionFormComponent,
        TrackingWarningDialogComponent,
        InformationDialogComponent,
        ShipmentTimeOutDialogComponent,
        ShipmentStatusFormComponent,
        BiAlertDialogComponent,
        CustomDialogComponent,
        ImportItemListComponent,
        StepsToEnableCookieComponent,
        ShipmentSelectionDialogComponent,
        ConfirmClaimsDialogComponent,
        InsuranceQuoteDialogComponent,
        InsuranceRateDialogComponent,
        FeedbackFormComponent,
        ImportFormDialogComponent,
        UploadDocumentFileComponent,
        ParcelInformationDialogComponent,
        SaveQuoteDetailsPageComponent
    ]
})
export class AppCommonModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: AppCommonModule,
            providers: [
            ]
        };
    }
}
